<template>
  <div id="seed-inventory" class="">
    <b-button class="btn-add" v-b-modal.modal-add-seed variant="primary">
      Add Seed
    </b-button>
    <b-modal id="modal-add-seed" title="Add Seed into Inventory" size="md" :hide-footer="true">
      <b-form v-on:submit.prevent="submitSeed" class="form-modal">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Lot #:"
          label-for="lot"
        >
          <b-form-input id="lot" type="text" v-model="seedform.lot" required>
          </b-form-input>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Name:"
          label-for="name"
        >
          <b-form-input id="name" type="text" v-model="seedform.name" required>
          </b-form-input>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Crop Type:"
          label-for="croptype"
        >
          <v-select
            id="croptype"
            :options="[
              'Asian Green',
              'Lettuce',
              'Microgreen',
              'Root Vegetables',
              'Herbs',
              'Fruit Vegetables',
            ]"
            v-model="seedform.cropType"
            :clearable="false"
            required
          >
          </v-select>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Source:"
          label-for="source"
        >
          <div class="row">
            <div class="col">
              <v-select
                id="source"
                v-if="!seedform.otherSource"
                :options="['GWG', 'Enza Zaden', 'Rijk Zwaan']"
                v-model="seedform.source"
                :clearable="false"
                required
              >
              </v-select>
              <b-form-input
                id="source"
                type="text"
                v-if="seedform.otherSource"
                v-model="seedform.source"
                required
              >
              </b-form-input>
            </div>
            <div class="col">
              <input
                class="form-check-input"
                id="source-others"
                v-model="seedform.otherSource"
                type="checkbox"
              />
              <label class="form-check-label" for="source-others">
                Others
              </label>
            </div>
          </div>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Price:"
          label-for="price"
        >
          <b-form-input
            id="price"
            type="number"
            min="0.0"
            step="0.01"
            v-model="seedform.price"
            required
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Total Quantity:"
          label-for="qtyT"
        >
          <div class="row">
            <div class="col">
              <b-form-input             
                id="qtyT"
                type="number"
                v-model="seedform.qtyTotal.value"
                required
              >
              </b-form-input>
            </div>
            <div class="col">
              <v-select
                :options="['grams', 'seeds']"
                :clearable="false"
                v-model="seedform.qtyTotal.unit"
                required
              >
              </v-select>
            </div>
          </div>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Expiry Date:"
          label-for="edate"
        >
          <b-form-input
            id="edate"
            type="date"
            v-model="seedform.edate"
            required
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Arrived Date:"
          label-for="adate"
        >
          <b-form-input
            id="adate"
            type="date"
            v-model="seedform.adate"
            required
          >
          </b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary" class="btn-submit">Submit</b-button>
      </b-form>
    </b-modal>

    <vue-good-table
      @on-selected-rows-change="selectionChanged"
      :columns="cols"
      :rows="seeds"
      :select-options="{ enabled: true }"
      :search-options="{ enabled: true }"
      styleClass="vgt-table seedling"
      :fixed-header="windowWidth > 1440"
      v-if="dataReady"
    >
      <div slot="selected-row-actions">
        <b-button v-b-modal.modal-archive-confirm variant="danger">Archive</b-button>
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'expiryDate'">
          {{ props.row.expiryDate | formatDate }}
        </span>
        <span v-else-if="props.column.field == 'arrivedDate'">
          {{ props.row.arrivedDate | formatDate }}
        </span>
        <span v-else-if="props.column.field == 'startDate'">
          {{ props.row.startDate | formatDate }}
        </span>
        <span v-else-if="props.column.field == 'endDate'">
          {{ props.row.endDate | formatDate }}
        </span>
        <span v-else-if="props.column.field == 'qtyUsed'">
          {{ props.row.qtyUsed.value }} {{ props.row.qtyUsed.unit }}
        </span>
        <span v-else-if="props.column.field == 'qtyTotal'">
          {{ props.row.qtyTotal.value }} {{ props.row.qtyTotal.unit }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div  v-else>
      <b-spinner label="Spinning"></b-spinner>
    </div>

    <b-modal id="modal-archive-confirm" title="Confirm Archival" size="lg">
      <p> These seed entries will be marked as exhausted: </p>
      <b-table bordered small :items="selected" />
      
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="sm" variant="danger" @click="archiveSeed">
          Archive
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "SeedInventory",
  watch: {
    farm: function(){
      this.getSeeds();
    }
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
    formatTime: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY hh:mm");
      }
    },
  },
  props: ["farm"],
  data() {
    return {
      windowWidth: window.innerWidth,
      cols: [
        {
          label: "Lot #",
          field: "lot",
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Crop Type",
          field: "cropType",
        },
        {
          label: "Source",
          field: "source",
        },
        {
          label: "Price",
          field: "price",
        },
        {
          label: "Qty. Used",
          field: "qtyUsed",
        },
        {
          label: "Total Qty.",
          field: "qtyTotal",
        },
        {
          label: "Expiry Date",
          field: "expiryDate",
        },
        {
          label: "Arrived Date",
          field: "arrivedDate",
        },
        {
          label: "Start Date",
          field: "startDate",
        },
        {
          label: "Exhausted Date",
          field: "endDate",
          filterOptions: {
            enabled: true,
            placeholder: "all",
            filterValue: "available",
            filterDropdownItems: ["available", "exhausted"],
            filterFn: (data, filterStr) => {
              if (filterStr === "available") {
                return data == null;
              } else if (filterStr === "exhausted") {
                return data != null;
              }
            },
          },
        },
      ],
      seedform: {
        name: "",
        cropType: "",
        source: "",
        otherSource: false,
        price: 0.01,
        lot: "",
        qtyTotal: { unit: "grams", value: 0 },
        edate: "",
        adate: "",
        farm: this.farm
      },
      seeds: [],
      selected: [],
      selected_id: [],
      dataReady : false,
    };
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.getSeeds();
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    submitSeed(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-add-seed");

      axios
        .post(`/${this.farm}/seeds`, this.seedform)
        .then((response) => {
          // JSON responses are automatically parsed.
          console.log(response);
          this.getSeeds();
          this.seedform = {
            name: "",
            cropType: "",
            source: "",
            price: 0.01,
            lot: "",
            qtyTotal: { unit: "grams", value: 0 },
            edate: "",
            adate: ""
          };
        })
        .catch((e) => {
          alert(e);
          console.log(e);
          if (e.response.status == 500) this.$router.push("/error")
          this.getSeeds();
        });
    },
    archiveSeed(evt) {
      evt.preventDefault();
      axios
        .put(`/${this.farm}/seeds/archive`, {
          seedId: this.selected_id
        })
        .then((response) => {
          // JSON responses are automatically parsed.
          console.log(response.data);
          this.getSeeds();
        })
        .catch((e) => {
          alert(e);
          if (e.response.status == 500) this.$router.push("/error")
          console.log(e);
        });
      this.$bvModal.hide("modal-archive-confirm");
    },
    selectionChanged(params) {
      this.selected = [];
      this.selected_id = [];
      for (const key in params.selectedRows) {
        const row = params.selectedRows[key];
        this.selected_id.push(row._id);

        const rowObj = {
            "Lot #": row.lot,
            "Variant": row.name,
            "Qty. Used": `${row.qtyUsed.value} ${row.qtyUsed.unit} / ${row.qtyTotal.value} ${row.qtyTotal.unit}`,
            "Expiry Date": this.$options.filters.formatDate(row.expiryDate),
            "Start Date": row.startDate ? this.$options.filters.formatDate(row.expiryDate) : "N/A"
          }
        this.selected.push(rowObj);
      }
    },
    getSeeds() {
      // Get seeds are still in inventory
      axios
        .get(`/${this.farm}/seeds`, { })
        .then((response) => {
          this.seeds = response.data;
          this.dataReady = true;
        })
        .catch((e) => {
          if (e.response.status == 500) this.$router.push("/error")
          alert(e);
        });
    },
  },
  beforeDestroy (){
    window.removeEventListener('resize', this.onResize);
  }
};
</script>
<style scoped>
table.vgt-table.seedling td {
  font-size: 13px;
}
</style>